<template>
  <DialogBase :isDialogVisible="dialogOpen">
    <template v-slot:header>
      <span>Edit Employee</span>
    </template>

    <template v-slot:content>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" class="pt-2">
            <p><strong>Personal Information</strong></p>
          </v-col>
          <v-col cols="6" class="pb-1 pt-1 pr-1">
            <v-text-field
              v-model="editedEmployee.firstName"
              label="First Name"
              variant="outlined"
              density="compact"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pb-1 pt-1 pl-1">
            <v-text-field
              v-model="editedEmployee.lastName"
              label="Last Name"
              variant="outlined"
              density="compact"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pb-1 pt-1">
            <v-select
              @update:modelValue="editedEmployee.titleId = ''"
              :items="employeeDepartments"
              v-model="editedEmployee.departmentId"
              item-title="department"
              item-value="employee_Department_ID"
              variant="outlined"
              density="compact"
              label="Department"
              :rules="[rules.required]"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pb-1 pt-1">
            <v-select
              :disabled="editedEmployee.departmentId === ''"
              :items="filteredTitles"
              v-model="editedEmployee.titleId"
              item-title="title"
              item-value="employee_Title_ID"
              variant="outlined"
              density="compact"
              label="Title"
              :rules="[rules.required]"
            ></v-select>
          </v-col>
          <v-col cols="8" class="pt-1 pr-1 pb-2">
            <v-text-field
              variant="outlined"
              density="compact"
              v-model="editedEmployee.userName"
              label="Username"
              suffix="@GlobalPrecisionGroup.com"
            ></v-text-field>
          </v-col>

          <v-col cols="4" class="pt-1 pl-1 pb-2">
            <v-select
              :items="shifts"
              v-model="editedEmployee.shift"
              item-title="title"
              item-value="key"
              variant="outlined"
              density="compact"
              label="Shift"
              :rules="[rules.required]"
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template v-slot:footer>
      <v-btn variant="text" @click="closeDialog">Cancel</v-btn>
      <v-btn
        color="primary"
        append-icon="mdi-pencil"
        variant="tonal"
        :loading="isAddBtnLoading"
        :disabled="!valid"
        @click="submitForm"
      >Edit Employee</v-btn>
    </template>
  </DialogBase>
</template>

<script>
import api from '@/services/api'
import DialogBase from './DialogBase.vue';

export default {
  name: 'EditEmployeeDialog',
  components: {
    DialogBase
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    targetEmployee: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      valid: false,
      isAddBtnLoading: false,
      shifts: [
        { title: '1st Shift', key: '1' },
        { title: '2nd Shift', key: '2' },
        { title: '3rd Shift', key: '3' },
      ],
      employeeTitles: [],
      employeeDepartments: [],
      editedEmployee: {
        firstName: '',
        lastName: '',
        shift: '',
        departmentId: '',
        titleId: '',
        userName: ''
      },
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 60 || 'Max characters reached',
      }
    }
  },
  mounted() {
    this.setEmployeeInformation();
    this.getEmployeeTitles();
    this.getEmployeeDepartments();
  },
  computed: {
    dialogOpen: {
      get() {
        return this.isDialogOpen;
      },
      set(value) {
        this.$emit('update:isDialogOpen', value);
      }
    },
    filteredTitles() {
      return this.employeeTitles.filter(
        title => title.department_ID === this.editedEmployee.departmentId
      );
    }
  },
  methods: {
    setEmployeeInformation() {
      this.editedEmployee.firstName = this.targetEmployee.first_Name;
      this.editedEmployee.lastName = this.targetEmployee.last_Name;
      this.editedEmployee.shift = this.targetEmployee.shift;
      this.editedEmployee.departmentId = this.targetEmployee.department_ID;
      this.editedEmployee.titleId = this.targetEmployee.title_ID;
      this.editedEmployee.userName = this.targetEmployee.user_ID;
    },
    async getEmployeeTitles() {
      try {
        const response = await api.get('/Employee/GetEmployeeTitles');
        if (response.status === 200) {
          this.employeeTitles = response.data.sort((a, b) => a.title.localeCompare(b.title));
          console.log(this.employeeTitles)
          return true;
        } else {
          console.log('Error fetching employee titles.');
          return false;
        }
      } catch (error) {
        console.error('API error:', error);
        return false;
      }
    },
    async getEmployeeDepartments() {
      try {
        const response = await api.get('/Employee/GetEmployeeDepartments');
        if (response.status === 200) {
          this.employeeDepartments = response.data.sort((a, b) => a.department.localeCompare(b.department));
          console.log(this.employeeDepartments)
          return true;
        } else {
          console.log('Error fetching employee departments.');
          return false;
        }
      } catch (error) {
        console.error('API error:', error);
        return false;
      }
    },
    closeDialog() {
      this.dialogOpen = false;
      this.resetForm();
    },
    async submitForm() {
      this.isAddBtnLoading = true;
      
      const employee = {
        Employee_ID: this.targetEmployee.employee_ID,
        First_Name: this.editedEmployee.firstName,
        Last_Name: this.editedEmployee.lastName,
        Shift: this.editedEmployee.shift,
        Title_ID: this.editedEmployee.titleId,
        Department_ID: this.editedEmployee.departmentId
      };

      try {
        await api.put('/Employee/EditEmployee', employee);
        await new Promise(resolve => setTimeout(resolve, 500));
        
        this.showSnackbar('success', 'mdi-check-circle', 'Successfully Edited Employee!', 'Edited ' + employee.First_Name + ' ' + employee.Last_Name + '.');
        this.refreshParentCollection();
        this.closeDialog();
      } catch (error) {
        console.error(error);
        this.showSnackbar('error', 'mdi-alert-circle', 'Unable to Edit Employee', 'An error occured while attempting to edit existing employee.');
      } finally {
        this.isAddBtnLoading = false;
      } 
    },
    resetForm() {
      this.$refs.form.reset();
    },
    refreshParentCollection() {
      this.$emit('refresh-employees');
    },
    showSnackbar(color, icon, title, description) {
      this.$emit('show-snackbar', {
        color,
        icon,
        title,
        description,
        timeout: 5000,
      });
    },
  }
}
</script>
